
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonAvatar,
    IonIcon,
    IonButton,
    IonButtons,
    IonSpinner,
    menuController,
    modalController,
    //onIonViewWillLeave,
} from "@ionic/vue";
import { ref } from "vue";
import { useRouter, onBeforeRouteLeave } from "vue-router";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import { menu } from "ionicons/icons";
import moment from "moment";
// Generazione QR
//import QRCodeVue3 from "qrcode-vue3";

import { dbUser, dbCustomers, dbDocuments, dbRichieste, dbPresenze, dbTimbrature } from "@/services/localbase";
import apiDipendenti from "@/services/dipendenti";
import { openToast } from "@/services/toast";

import ModalAutorizzazioni from "@/components/impostazioni/ModalAutorizzazioni.vue";

export default {
    name: "Profilo",
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonAvatar,
        IonIcon,
        IonButton,
        IonButtons,
        IonSpinner,
        //QRCodeVue3,
    },
    setup() {
        const router = useRouter();

        const user = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`));
        const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;
        const loadingQR = ref(false);

        const profileImagePath = ref(null);
        function initImage() {
            if (user.dipendenti_foto) {
                profileImagePath.value = `${process.env.VUE_APP_BASE_URL}/uploads/${user.dipendenti_foto}`;
                //return `${process.env.VUE_APP_BASE_URL}/uploads/${user.dipendenti_foto}`;
            } else {
                profileImagePath.value = `${process.env.VUE_APP_BASE_URL}/images/user.png`;
                //return `${process.env.VUE_APP_BASE_URL}/images/user.png`;
            }
        }
        initImage();

        const openMenu = () => {
            menuController.open("app-menu");
        };

        function changePassword() {
            console.log("test");
            router.push("/changePassword");
        }

        /**
         * Open modal to create new presenza
         */
        async function openAutorizzazioni() {
            //console.log(spesaDetail);
            const modal = await modalController.create({
                component: ModalAutorizzazioni,
            });
            return modal.present();
        }

        /**
         * Set user avatar image
         */
        function setImage(user) {
            if (user.dipendenti_foto) {
                return `${process.env.VUE_APP_BASE_URL}/uploads/${user.dipendenti_foto}`;
            } else {
                return `${process.env.VUE_APP_BASE_URL}/images/user.png`;
            }
        }

        /**
         * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
         */
        function dateFormat(date) {
            if (date && moment.isDate(new Date(date))) {
                return moment(date).format("DD/MM/YYYY");
            } else {
                return "-";
            }
        }


        /**
         * ! Mostra QR dipendente
         * * 03/06/2023 - Non genero più stringa random all'apertura ma mostro sempre lo stesso tramite chiamata a controller
         */
        const showQrImage = ref(false);

        const b64Image = ref(null);
        async function loadDipendenteQr() {
            loadingQR.value = true;

            const res: any = await apiDipendenti.getQrDipendente(dipendenteID);
            if (res.data.status == 0) {
                showQrImage.value = false;
                b64Image.value = null;
                loadingQR.value = false;
                openToast(`${res.data.txt}`, "toast_danger");
                return;
            }

            showQrImage.value = true;
            b64Image.value = `data:image/png;base64,${res.data}`;
            loadingQR.value = false;
        }
        //Mostro direttament4 QR
        loadDipendenteQr();

        function closeQrImage() {
            showQrImage.value = false;
        }

        /**
         * 
         * ! Load new user profile image
         * 
         */
        const showThumb = ref(false);
        const fileInput = ref(null);
        const newProfileImage = ref(null);

        function loadNewImage() {
            fileInput.value.click();
        }

        function handleImageUpload(event) {
            const file = event.target.files[0];
            if (!file) return;

            // Verifica che il file sia un'immagine
            if (!file.type.startsWith("image/")) {
                openToast("Seleziona un file immagine valido", "toast_danger");
                return;
            }

            const reader = new FileReader();
            reader.onload = () => {
                newProfileImage.value = reader.result; // Memorizza il Base64 per la preview
            };
            reader.readAsDataURL(file);

            showThumb.value = true;
        }

        // Salvataggio foto profilo e chiusura modale
        function removeBase64Prefix(base64) {
            return base64.replace(/^data:image\/[a-z]+;base64,/, "");
        }

        const savingPhoto = ref(false);
        async function savePhoto() {
            savingPhoto.value = true;
            try {
                if(!newProfileImage.value) {
                    savingPhoto.value = false;
                    openToast('Seleziona una foto da caricare', "toast_danger");
                    return;
                }
                const base64 = removeBase64Prefix(newProfileImage.value);                
                const response =  await apiDipendenti.uploadProfileImage(dipendenteID, base64);

                if(response.status === 200 && response.data.status === 1 && response.data.data.length != 0) {
                    // Aggiorno dati nel locastorage e ref foto
                    localStorage.setItem(`${LOCALSTORAGE_KEY}`, JSON.stringify(response.data.data));
                    profileImagePath.value = `${process.env.VUE_APP_BASE_URL}/uploads/${response.data.data.dipendenti_foto}`;

                    openToast('Foto profilo modificata con successo', "toast_success");
                    closeModal();
                } else {
                    openToast(response.data.txt, "toast_danger");
                }
            } catch (error) {
                openToast('Si è verificato un errore durante il caricamento della foto', "toast_danger");
            } finally {
                savingPhoto.value = false;
            }
        }

        function closeModal() {
            showThumb.value = false;
            newProfileImage.value = null;
        }



        /**
         * ! Force app update
         */
        function updateApp() {
            window.location.reload();
        }

        /**
         * ! Delete localbase CLIENTI and USER database
         */
        async function deleteDb() {
            /*       const deleteUser = await dbUser.collection("user").delete();
      const deleteDocuments = await dbDocuments
        .collection("documents")
        .delete();
      const deleteRichieste = await dbRichieste
        .collection("richieste")
        .delete();
      const deletePresenze = await dbPresenze.collection("presenze").delete();
      const deleteTimbrature = await dbTimbrature
        .collection("timbrature")
        .delete(); */

            dbUser.delete().then((response) => {
                console.log(response);
            });
            dbDocuments.delete().then((response) => {
                console.log(response);
            });
            dbRichieste.delete().then((response) => {
                console.log(response);
            });
            dbPresenze.delete().then((response) => {
                console.log(response);
            });
            dbTimbrature.delete().then((response) => {
                console.log(response);
            });
        }

        /**
         * Delete Localbase databases and clear localStorage
         * Redirect user to Login page
         */
        const isLoggingOut = ref(false); // Stato per indicare se è in corso un logout
        function logOut() {
            isLoggingOut.value = true;

            deleteDb().then(() => {
                localStorage.clear();
                router.replace("/login");
            });
        }

        const fotoButton = ref(null);
        const inputButton = ref(null);
        const triggerCameraButton = () => {
            if (fotoButton.value) {
                inputButton.value.click();
            }
        };


        // Hook per rilevare l'uscita dalla pagina
        onBeforeRouteLeave((to, from, next) => {
            if (isLoggingOut.value) {
                window.location.reload(); // Fai il reload solo se l'utente sta facendo logout
            }
            next(); // Continua la normale navigazione
        });

        return {
            user,
            setImage,
            dateFormat,
            logOut,
            updateApp,
            menu,
            openMenu,
            profileImagePath,
            //Gestione codice QR image
            showQrImage,
            closeQrImage,
            //randomString,
            b64Image,
            loadingQR,
            changePassword,
            fotoButton,
            inputButton,
            triggerCameraButton,
            openAutorizzazioni,
            // Profile image
            loadNewImage,
            fileInput,
            handleImageUpload,
            newProfileImage,
            showThumb,
            closeModal,
            savePhoto,
            savingPhoto
        };
    },
};
